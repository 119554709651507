import React from 'react';
import PropTypes from 'prop-types';

import NoChromeComponents from './NoChromeComponents';
import ChromedComponents from './ChromedComponents';

const ComponentsWrapper = () => (
  <>
    <NoChromeComponents />
    <ChromedComponents />
  </>
);

export default ComponentsWrapper;
