import React, { useContext, useEffect, useState } from 'react';

import ConfigContext from '../../utils/ConfigContext/ConfigContext';
import { getAuthClient } from '../../utils/auth';

import FloatingModal from '../Modal/FloatingModal';
import TimeoutMessage from './TimeoutMessage';

import './Timeout.scss';

const auth = getAuthClient();

const timeoutMin = 60;
const timeoutSec = 0;
const idleWarningMin = 0;
const idleWarningSec = 30;

// for testing
// const timeoutMin = 0;
// const timeoutSec = 20;
// const idleWarningMin = 0;
// const idleWarningSec = 10;

const Timeout = () => {
  const [ showIdleWarning, setShowIdleWarning ] = useState( false );
  const [ isIdle, setIsIdle ] = useState( true );
  const [ minutes, setMinutes ] = useState( timeoutMin );
  const [ seconds, setSeconds ] = useState( timeoutSec );
  const [ modalIsOpen, setModalIsOpen ] = useState( true );
  const [ enableTimeout, setEnableTimeout ] = useState( false );

  const {
    setPersonalizationActive,
    settings,
    appStatus,
  } = useContext( ConfigContext );

  const {
    isPIB, isMapEmbed, isKiosk, isMapTiles,
  } = settings;

  useEffect( () => {
    if ( appStatus === 'operational'
      && !isPIB
      && !isMapEmbed
      && !isKiosk
      && !isMapTiles
    ) {
      setEnableTimeout( true );
    } else {
      setEnableTimeout( false );
    }
  }, [
    appStatus, isPIB, isMapEmbed, isKiosk, isMapTiles,
  ] );

  // attach listeners for any movement for timeout
  useEffect( () => {
    if ( enableTimeout ) {
      [
        'mousemove',
        'keydown',
        'wheel',
        'DOMMouseScroll',
        'mousewheel',
        'mousedown',
        'touchstart',
        'touchmove',
        'MSPointerDown',
        'MSPointerMove',
        'visibilitychange',
      ].forEach( ( event ) => {
        window.addEventListener( event, () => {
          // trigger the reset of the countdown
          setIsIdle( false );
          // return the state to default
          setIsIdle( true );
        } );
      } );
    }
  }, [ enableTimeout ] );

  // reset countdown if user is not idle
  // don't let movement events reset the countdown once the warning modal shows
  useEffect( () => {
    if ( !isIdle && !showIdleWarning ) {
      setMinutes( timeoutMin );
      setSeconds( timeoutSec );
    }
  }, [ isIdle, showIdleWarning ] );

  // countdown
  useEffect( () => {
    if ( enableTimeout ) {
      const myInterval = setInterval( () => {
        if ( seconds > 0 ) {
          setSeconds( seconds - 1 );
        }
        if ( seconds === 0 ) {
          if ( minutes === 0 ) {
            clearInterval( myInterval );
          } else {
            setMinutes( minutes - 1 );
            setSeconds( 59 );
          }
        }
      }, 1000 );
      return () => {
        clearInterval( myInterval );
      };
    }
  }, [ enableTimeout, minutes, seconds ] );

  // warn that timeout is coming - show idle warning
  useEffect( () => {
    if ( minutes === idleWarningMin && seconds === idleWarningSec ) {
      setShowIdleWarning( true );
    }
  }, [ minutes, seconds ] );

  // timeout the app and close modal
  useEffect( () => {
    // on load, min and sec are 0
    // so we also have to check that the showIdleWarning has been triggered
    // so we dont close the app on load, just when the countdown has ended
    if ( minutes === 0 && seconds === 0 && showIdleWarning === true ) {
      setShowIdleWarning( false );
      setPersonalizationActive( false );
      auth.logout();
    }
  }, [
    minutes, seconds, showIdleWarning,
  ] );

  // reset countdown on click
  const handleClick = () => {
    setIsIdle( false );
    setShowIdleWarning( false );
    setModalIsOpen( false );
  };

  return showIdleWarning && (
    <FloatingModal
      modalIsOpen
      setModalIsOpen={setModalIsOpen}
      modalSize="small"
    >
      <div className="timeout">

        <div className="floatingModal__header">
          YourTour Session Expiring
          {' '}
          <span />
          Soon
        </div>

        <TimeoutMessage />

        <div className="timeout__countdown">
          <div className="timeout__countdownClock">
            {minutes}
            :
            { seconds < 10 ? `0${seconds}` : seconds}
          </div>
        </div>

        <div className="floatingModal__copy">
          To resume the session, click the button below.
        </div>

        <div className="timeout__resume">
          <button
            type="button"
            className="floatingModal__cta"
            onClick={handleClick}
          >
            Resume Session
          </button>
        </div>

      </div>
    </FloatingModal>
  );
};

export default Timeout;
