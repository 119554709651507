import React, {
  useState, useRef, useEffect, useContext,
} from 'react';

import MapContext from '../../MapPage/MapContext';

const ZoomZone = ( { zoomZone } ) => {
  const { center, name, orientation } = zoomZone;
  // These values are width and height of SVG received from Sketch.
  const svgWidth = 43;
  const svgHeight = 43;
  const zoomZoneCenter = {
    x: ( center.x - ( svgWidth / 2 ) ),
    y: ( center.y - ( svgHeight / 2 ) ),
  };
  const { mapZoomToEnableLevels, setActiveZoom, mapInstance } = useContext( MapContext );
  const zoomZoneIconRef = useRef( null );
  const textRef = useRef( null );
  const [ svgSize, setSvgSize ] = useState( null );
  const [ rectSize, setRectSize ] = useState( { width: 0, height: 0 } );
  const [ textX, setTextX ] = useState( 0 );
  const [ textY, setTextY ] = useState( 0 );
  const [ bgX, setBgX ] = useState( 0 );
  const [ bgY, setBgY ] = useState( 0 );
  const [ bgWidth, setBgWidth ] = useState( 0 );
  const [ bgHeight, setBgHeight ] = useState( 0 );
  // We intentionally had to use Ref instead of states.
  const clickListenerEnabledRef = useRef( false );

  const zoomZoneClicked = () => {
    clickListenerEnabledRef.current = true;
  };

  useEffect( () => {
    if ( mapInstance ) {
      mapInstance.addListener( 'click', ( event ) => {
        setTimeout( () => {
          // The timeout is required because the click on the map is getting
          // called before zoomZoneClicked.
          if ( !clickListenerEnabledRef.current ) {
            return;
          }
          setActiveZoom( mapZoomToEnableLevels );
          const location = new window.google.maps.LatLng( event.latLng.lat(), event.latLng.lng() );
          mapInstance.setCenter( location );
          clickListenerEnabledRef.current = false;
        }, 0 );
      } );
    }
  }, [ mapInstance, clickListenerEnabledRef ] );

  useEffect( () => {
    if ( textRef.current ) {
      const { width, height } = textRef.current.getBBox();
      setRectSize( { width: width + 30, height: height + 10 } );
    }
    if ( zoomZoneIconRef.current ) {
      setSvgSize( zoomZoneIconRef.current.getBBox() );
    }
  }, [ zoomZone ] );

  const horizontalTextPadding = 15;
  const verticalTextPadding = 7.5;

  useEffect( () => {
    if ( svgSize && rectSize && zoomZoneCenter && orientation ) {
      setBgWidth( rectSize.width );
      setBgHeight( rectSize.height );

      switch ( orientation ) {
        case 'top':
          setBgX( zoomZoneCenter.x - ( rectSize.width / 2 ) + ( svgSize.width / 2 ) );
          setBgY( zoomZoneCenter.y - svgSize.height );
          setTextX( zoomZoneCenter.x + horizontalTextPadding - ( rectSize.width / 2 ) + ( svgSize.width / 2 ) );
          setTextY( zoomZoneCenter.y + 2.5 - ( svgSize.height / 2 ) );
          break;
        case 'bottom':
          setBgX( zoomZoneCenter.x - ( rectSize.width / 2 ) + ( svgSize.width / 2 ) );
          setBgY( zoomZoneCenter.y + ( svgSize.height * 1.25 ) );
          setTextX( zoomZoneCenter.x + horizontalTextPadding - ( rectSize.width / 2 ) + ( svgSize.width / 2 ) );
          setTextY( zoomZoneCenter.y + rectSize.height + svgSize.height );
          break;
        case 'left':
          setBgX( zoomZoneCenter.x - rectSize.width - ( svgSize.width * 0.25 ) );
          setBgY( zoomZoneCenter.y + 5 );
          setTextX( zoomZoneCenter.x + horizontalTextPadding - rectSize.width - ( svgSize.width * 0.25 ) );
          setTextY( zoomZoneCenter.y + verticalTextPadding + ( svgSize.height / 2 ) );
          break;
        case 'right':
          setBgX( zoomZoneCenter.x + ( svgSize.width * 1.25 ) );
          setBgY( zoomZoneCenter.y + 5 );
          setTextX( zoomZoneCenter.x + horizontalTextPadding + ( svgSize.width * 1.25 ) );
          setTextY( zoomZoneCenter.y + verticalTextPadding + ( svgSize.height / 2 ) );
          break;
        default:
          break;
      }
    }
  }, [
    zoomZoneCenter, orientation, rectSize, svgSize,
  ] );

  return (
    <g className="zMarker individual-zoom-zone" onClick={zoomZoneClicked}>
      <svg
        x={zoomZoneCenter.x}
        y={zoomZoneCenter.y}
        width="43px"
        height="43px"
        viewBox="0 0 43 43"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className="zMarker__icon"
        ref={zoomZoneIconRef}
      >
        <defs>
          <circle id="path-6-ibeosj-w-1" cx="21.5" cy="21.5" r="21.5" />
          <filter
            x="-75.6%"
            y="-75.6%"
            width="297.7%"
            height="297.7%"
            filterUnits="objectBoundingBox"
            id="filter-6-ibeosj-w-2"
          >
            <feOffset
              dx="10"
              dy="10"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            />
            <feGaussianBlur
              stdDeviation="12.5"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            />
            <feColorMatrix
              values="0 0 0 0 0.68627451   0 0 0 0 0.690196078   0 0 0 0 0.666666667  0 0 0 0.3 0"
              type="matrix"
              in="shadowBlurOuter1"
            />
          </filter>
          <polygon id="path-6-ibeosj-w-3" points="0 0 31 0 31 31 0 31" />
        </defs>
        <g
          className="zoom-zone-icon"
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Zoom-Zones" transform="translate(-778, -339)">
            <g id="Group-14" transform="translate(658, 297)">
              <g id="Group-13" transform="translate(120, 42)">
                <g id="Oval">
                  <use
                    fill="black"
                    fillOpacity="1"
                    filter="url(#filter-6-ibeosj-w-2)"
                    xlinkHref="#path-6-ibeosj-w-1"
                    className="zMarker__visible"
                  />
                  <use
                    fill="#FFFFFF"
                    fillRule="evenodd"
                    xlinkHref="#path-6-ibeosj-w-1"
                    className="zMarker__visible"
                  />
                </g>
                <g id="Group-6" transform="translate(6, 6)">
                  <g id="Group-3">
                    <mask id="mask-6-ibeosj-w-4" fill="white">
                      <use xlinkHref="#path-6-ibeosj-w-3" />
                    </mask>
                    <g id="Clip-2" />
                    <path
                      d="M15.5003287,2.46500243 C8.31294464,2.46500243 2.46492679,8.31300478 2.46492679,15.5003698 C2.46492679,22.6877347 8.31294464,28.5349154 15.5003287,28.5349154 C22.6877127,28.5349154 28.5349089,22.6877347 28.5349089,15.5003698 C28.5349089,8.31300478 22.6877127,2.46500243 15.5003287,2.46500243 M15.5003287,31 C6.95385774,31 -0.000164339409,24.0468181 -0.000164339409,15.5003698 C-0.000164339409,6.95309978 6.95385774,-8.21694865e-05 15.5003287,-8.21694865e-05 C24.0467996,-8.21694865e-05 31,6.95309978 31,15.5003698 C31,24.0468181 24.0467996,31 15.5003287,31"
                      id="Fill-1"
                      fill="#495A49"
                      mask="url(#mask-6-ibeosj-w-4)"
                    />
                  </g>
                  <path
                    d="M16.6389717,14.3602688 L16.6389717,10.1393514 C16.6389717,9.50966988 16.1293018,9 15.4996202,9 C14.8706982,9 14.3602688,9.50966988 14.3602688,10.1393514 L14.3602688,14.3602688 L10.1393514,14.3602688 C9.51042945,14.3602688 9,14.8699387 9,15.4996202 C9,16.1293018 9.51042945,16.6389717 10.1393514,16.6389717 L14.3602688,16.6389717 L14.3602688,20.8606486 C14.3602688,21.4895706 14.8706982,22 15.4996202,22 C16.1293018,22 16.6389717,21.4895706 16.6389717,20.8606486 L16.6389717,16.6389717 L20.8606486,16.6389717 C21.4895706,16.6389717 22,16.1293018 22,15.4996202 C22,14.8699387 21.4895706,14.3602688 20.8606486,14.3602688 L16.6389717,14.3602688 Z"
                    id="Fill-4"
                    fill="#495A49"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <rect
        rx="8"
        ry="8"
        x={bgX}
        y={bgY}
        width={bgWidth}
        height={bgHeight}
        className="zMarker__bg zMarker__visible"
      />
      <text
        x={textX}
        y={textY}
        className="zMarker__text zMarker__visible"
        ref={textRef}
      >
        {name}
      </text>
    </g>
  );
};

export default ZoomZone;
