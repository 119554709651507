import React, {useContext} from 'react';
import {
  Route, Switch,
} from 'react-router-dom';

import ConfigContext from '../../utils/ConfigContext/ConfigContext';

import NavBar from '../Page/NavBar/NavBar';
import HomePage from '../HomePage/HomePage';
import MapPage from '../MapPage/MapPage';
import AmenitiesPage from '../AmenitiesPage/AmenitiesPage';
import MediaPage from '../MediaPage/MediaPage';
import FloorPlansPage from '../FloorPlansPage/FloorPlansPage';
import Timeout from '../Timeout/Timeout';

const ChromedComponents = () => {
  const {
    config,
    mapConfig,
  } = useContext( ConfigContext );

  const { secondaryColor, galleryUuid } = config;


  return (
    <>
      <NavBar />

      <Timeout />

      <Switch>

        <Route
          path="/"
          exact
          render={() => (
            <HomePage
              galleryUuid={galleryUuid}
            />
          )}
        />

        <Route
          path="/map"
          exact
          render={() => (
            <MapPage
              mapData={mapConfig}
              secondaryColor={secondaryColor}
              isMapEmbed={false}
            />
          )}
        />

        <Route
          path="/amenities"
          component={AmenitiesPage}
        />

        <Route
          path="/floorplans"
          component={FloorPlansPage}
        />

        <Route
          path="/media"
          component={MediaPage}
        />

      </Switch>
    </>
  );
};

export default ChromedComponents;
